import Twitch from "#lucide-solid/icons/twitch";

import { css } from "#style/css";
import { VStack } from "#style/jsx";
import { vstack } from "#style/patterns";

import { Button } from "#root/components/Button";
import { Logo } from "#root/components/Logo";
import { Typography } from "#root/components/Typography";
import { CsrfForm } from "#root/components/CsrfForm";
import { getLoginUrl } from "#root/module/authentication";
import { Link } from "#root/components/Link";
import { useTranslation } from "#root/domain/i18n";
import { SEO } from "#root/components/SEO";

export default function Login() {
  // TODO: Error handling
  // const [params] = useSearchParams<{ redirect: "error" }>();
  const { t } = useTranslation();

  return (
    <>
      <SEO />
      <VStack
        pt="24"
        w="full"
        minH="full"
        gap="20"
        backgroundColor="linen"
        p="4"
      >
        <Logo tag="h1" />
        <Typography
          tag="h2"
          textStyle="titleDisplay"
          class={css({
            textAlign: "center",
          })}
        >
          {t("login.tagLine")[0]}
          <br />
          {t("login.tagLine")[1]}
        </Typography>
        <Typography class={css({ textAlign: "center" })}>
          {t("login.tagText")}
        </Typography>

        <CsrfForm
          action={getLoginUrl("twitch")}
          method="get"
          class={vstack({
            justifyContent: "center",
            alignItems: "center",
            gap: "4",
          })}
        >
          <Button
            tag="button"
            leftIcon={() => <Twitch />}
            type="submit"
            size="lg"
          >
            {t("login.twitchCta")}
          </Button>
          <Typography textStyle="xxsmall" class={css({ textAlign: "center" })}>
            {t("login.termsLine")[0]}
            <Link href="/privacy" class={css({ textDecoration: "underline" })}>
              {t("login.termsLine")[1]}
            </Link>
            {t("login.termsLine")[2]}
            <Link href="/terms" class={css({ textDecoration: "underline" })}>
              {t("login.termsLine")[3]}
            </Link>
            {t("login.termsLine")[4]}
          </Typography>
        </CsrfForm>
      </VStack>
    </>
  );
}
